<template>
    <div>
        <div class="register_container">
            <div class="register_left-side">
                <router-link to="/">
                    <div class="register_title">
                    </div>
                </router-link>
                <div class="register_left-content">
                    <img src="@/assets/images/register_img.png" alt="signUp_img">
                </div>
            </div>

            <div class="register_right-side">
                <div class="account_text">
                    <div class="dont_have">
                        Already have an account?
                        <router-link to="/login"><span class="sign_text"> Login</span></router-link>
                    </div>
                </div>
                <div class="register_right-side-content">
                    <div class="welcome_title">
                        Create account
                    </div>
                    <div class="tabs">
                        <b-card no-body style="border: none">
                            <div class="tab_line"></div>
                            <b-tabs pills lazy>
                                <b-tab title="Account Data" active @click="changeTab('account')">
                                    <div class="tab_container">
                                        <form @submit.prevent="signUp()">
                                            <div class="dual_inputs">
                                                <div class="dual_input_item form-group">
                                                    <label>First Name</label>
                                                    <b-form-input
                                                            v-model="userForm.firstName"
                                                            type="text"
                                                            :class="{ 'is-invalid': isSubmitted && $v.userForm.firstName.$error }"
                                                            trim
                                                    ></b-form-input>
                                                    <div v-if="isSubmitted && !$v.userForm.firstName.required" class="invalid-feedback">
                                                        First Name field is required
                                                    </div>
                                                </div>
                                                <div class="dual_input_item form-group">
                                                    <label>Last Name</label>
                                                    <b-form-input
                                                            v-model="userForm.lastName"
                                                            type="text"
                                                            :class="{ 'is-invalid': isSubmitted && $v.userForm.lastName.$error }"
                                                            trim
                                                    ></b-form-input>
                                                    <div v-if="isSubmitted && !$v.userForm.lastName.required" class="invalid-feedback">Last
                                                        Name field is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="triple_inputs">
                                                <div class="triple_input_item form-group">
                                                    <label>City</label>
                                                    <b-form-input
                                                            v-model="userForm.city"
                                                            type="text"
                                                            :class="{ 'is-invalid': isSubmitted && $v.userForm.city.$error }"
                                                            trim
                                                    ></b-form-input>
                                                    <div v-if="isSubmitted && !$v.userForm.city.required" class="invalid-feedback">
                                                        City field is required
                                                    </div>
                                                </div>
                                                <div class="triple_input_item form-group">
                                                    <label>State</label>
                                                    <b-form-input
                                                            v-model="userForm.state"
                                                            type="text"
                                                            :class="{ 'is-invalid': isSubmitted && $v.userForm.state.$error }"
                                                            trim
                                                    ></b-form-input>
                                                    <div v-if="isSubmitted && !$v.userForm.state.required" class="invalid-feedback">
                                                        State field is required
                                                    </div>
                                                </div>
                                                <div class="triple_input_item form-group zip">
                                                    <label>Zip Code</label>
                                                    <b-form-input
                                                            v-model="userForm.zipCode"
                                                            type="text"
                                                            :class="{ 'is-invalid': isSubmitted && $v.userForm.zipCode.$error }"
                                                            trim
                                                    ></b-form-input>
                                                    <div v-if="isSubmitted && !$v.userForm.zipCode.required" class="invalid-feedback">
                                                        Zip code is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input_item form-group">
                                                <label>Email</label>
                                                <b-form-input
                                                        id="email"
                                                        v-model="userForm.email"
                                                        type="email"
                                                        :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }"
                                                        trim
                                                ></b-form-input>
                                                <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                                                    <span v-if="!$v.userForm.email.required">Email field is required</span>
                                                    <span v-if="!$v.userForm.email.email">Please provide valid email</span>
                                                </div>
                                                <span v-if="errors['email']" class="text_danger">{{errors['email'][0]}}</span>
                                            </div>
                                            <div class="dual_inputs">
                                                <div class="dual_input_item form-group">
                                                    <label>Password</label>
                                                    <b-form-input
                                                            id="password"
                                                            :type="r_pass"
                                                            v-model="userForm.password"
                                                            :class="{ 'is-invalid': isSubmitted && $v.userForm.password.$error }"
                                                            trim
                                                    ></b-form-input>
                                                    <div class="eye_close" @click="showPassword('r_pass')"></div>
                                                    <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">
                                                        <span v-if="!$v.userForm.password.required">Password field is required</span>
                                                        <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>
                                                    </div>
                                                </div>
                                                <div class="dual_input_item form-group">
                                                    <label>Confirm</label>
                                                    <b-form-input
                                                            id="confirm"
                                                            :type="r_confirm"
                                                            v-model="userForm.c_password"
                                                            :class="{ 'is-invalid': isSubmitted && $v.userForm.c_password.$error }"
                                                            trim
                                                    ></b-form-input>
                                                    <div class="eye_close" @click="showPassword('r_confirm')"></div>
                                                    <div v-if="isSubmitted && $v.userForm.c_password.$error" class="invalid-feedback">
                                                        <span v-if="!$v.userForm.c_password.required">Confirm Password field is required</span>
                                                        <span v-else-if="!$v.userForm.c_password.sameAsPassword">Passwords should be matched</span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="d-flex mt-2 mb-3 flex-column">
                                                <!--                            <div class="agree_terms form-group form-check">-->
                                                <!--                                <input type="checkbox" v-model="userForm.admin" id="admin" class="mt-1">-->
                                                <!--                                <label for="admin">-->
                                                <!--                                    Admin-->
                                                <!--                                </label>-->
                                                <!--                            </div>-->
                                                <div class="agree_terms form-group form-check">
                                                    <input type="checkbox" v-model="userForm.agree" @change="$v.userForm.agree.$touch()"
                                                           id="agree_terms" class="mt-1">
                                                    <label for="agree_terms"
                                                           :class="{ 'is-invalid': isSubmitted && $v.userForm.agree.$error }">
                                                        By creating an account, you agree to the <span @click.prevent="terms()">Terms of Service</span> and conditions and
                                                        <span @click.prevent="policy()"> Privacy Policy.</span>
                                                    </label>
                                                </div>
                                                <div v-if="isSubmitted && $v.userForm.agree.$error" class="invalid-feedback">
                                                    <span v-if="!$v.userForm.agree.required">Accept terms and conditions</span>
                                                </div>

                                            </div>
                                            <div class="form-group next_btn-container">
                                                                            <b-button class="next_btn" type="submit">Next</b-button>
<!--                                                <b-button variant="primary" class="register_btn" type="submit">Sign Up</b-button>-->
                                            </div>
                                        </form>


                                    </div>
                                </b-tab>
                                <b-tab title="Card Data" @click="changeTab('card')" ref="next_tab">
                                    <div class="tab_container">
                                        <div class="input_item form-group">
                                            <label>Card Name</label>
                                            <b-form-input
                                                    placeholder="Surname Name"
                                                    v-model="credit_card.cardName"
                                                    onKeyPress="if(this.value.length==36) return false;"
                                                    type="text"
                                                    trim
                                            ></b-form-input>

                                        </div>
                                        <div class="input_item form-group">
                                            <label>Card Number</label>
                                            <b-form-input
                                                    placeholder="Card Number"
                                                    v-model="credit_card.cardNumber"
                                                    type="number"
                                                    pattern="/^-?\d+\.?\d*$/"
                                                    onKeyPress="if(this.value.length==16) return false;"
                                                    trim
                                            ></b-form-input>
                                        </div>

                                        <div class="input_item form-group">
                                            <label>Card Date</label>
                                            <div class="date_inputs">
                                                <b-form-select v-model="credit_card.cardMonth">
                                                    <template #first>
                                                        <b-form-select-option value="" disabled selected>MM
                                                        </b-form-select-option>
                                                    </template>
                                                    <option
                                                            v-for="option in 12"
                                                            :value="option"
                                                            :title="option || null"
                                                    >
                                                        {{option}}
                                                    </option>
                                                </b-form-select>
                                                /
                                                <b-form-input
                                                        placeholder="YYYY"
                                                        v-model="credit_card.cardYear"
                                                        type="number"
                                                        pattern="/^-?\d+\.?\d*$/"
                                                        onKeyPress="if(this.value.length==4) return false;"
                                                        trim
                                                ></b-form-input>
                                            </div>
                                        </div>
                                        <div class="input_item form-group">
                                            <label>Card CVC</label>
                                            <b-form-input
                                                    placeholder="CVV"
                                                    v-model="credit_card.cardCvv"
                                                    type="number"
                                                    pattern="/^-?\d+\.?\d*$/"
                                                    onKeyPress="if(this.value.length==4) return false;"
                                                    trim
                                            ></b-form-input>
                                            <div class="mt-1">
                                            </div>
                                        </div>
                                        <span class="text_danger mt-1" v-if="cardError">{{cardError}}</span>
                                        <div class="account_btn-container">
                                            <b-button class="account_btn" @click="saveCard()">Create Account</b-button>
                                        </div>

                                    </div>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>

                    <router-link to="/">
                        <div class="back_website">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                <g id="arrow-left-circle" transform="translate(-1 -1)">
                                    <circle id="Ellipse_8" data-name="Ellipse 8" cx="10" cy="10" r="10"
                                            transform="translate(2 2)" fill="none" stroke="#409edc"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_238" data-name="Path 238" d="M12,8,8,12l4,4" fill="none"
                                          stroke="#409edc" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"/>
                                    <line id="Line_128" data-name="Line 128" x1="8" transform="translate(8 12)"
                                          fill="none" stroke="#409edc" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"/>
                                </g>
                            </svg>
                            Cancel and return to website
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {required, email, minLength, sameAs} from "vuelidate/lib/validators";
    import {mapActions} from "vuex";

    export default {
        name: "Register",
        data() {
            return {
                userForm: {
                    firstName: '',
                    lastName: '',
                    state: '',
                    city: '',
                    zipCode: '',
                    email: '',
                    password: '',
                    c_password: '',
                    agree: false,
                },
                isSubmitted: false,
                r_pass: 'password',
                r_confirm: 'password',
                errors: [],
                credit_card: {
                    cardName: '',
                    cardNumber: '',
                    cardMonth: '',
                    cardYear: '',
                    cardCvv: '',
                },
                cardError: '',

            }
        },
        validations: {
            userForm: {
                firstName: {
                    required
                },
                lastName: {
                    required
                },
                state: {
                    required
                },
                city: {
                    required
                },
                zipCode: {
                    required
                },
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
                c_password: {
                    required,
                    sameAsPassword: sameAs('password')
                },
                agree: {
                    required(val) {
                        return val
                    }
                }
            }
        },
        created() {
            if(this.$store.state.register_user.plan_id === null ) {
                this.$router.push('/pricing')
            }
        },
        mounted() {
            this.userForm.firstName = this.$store.state.register_user.firstName
            this.userForm.lastName = this.$store.state.register_user.lastName
            this.userForm.city = this.$store.state.register_user.city
            this.userForm.zipCode = this.$store.state.register_user.zip_code
            this.userForm.email = this.$store.state.register_user.email
        },
        methods: {
            ...mapActions(['registerUser', 'addNewCreditCard', 'userSubscribe', 'getUserData']),
            changeTab(type) {
                if(type === 'account') {
                    console.log('account')
                }
                this.$refs.next_tab.active = false;
            },
            saveCard() {
                this.cardError = ''
                this.addNewCreditCard(this.credit_card)
                .then(res => {
                    if(res.status === 200) {
                        if(res.data.success.card_id) {
                            let sendData = {
                                plan_id: this.$store.state.register_user.plan_id,
                                subscription_type: this.$store.state.register_user.subscription_type
                            }
                            this.userSubscribe(sendData)
                            .then(res => {
                                if(res.status === 200) {
                                    this.getUserData()
                                    .then(res => {
                                        if(res.status === 200) {
                                            this.$router.push('/dashboardLeads')
                                        }
                                    })
                                }
                            })
                        }
                        console.log(res, 'card result')
                    } else {
                        this.cardError = res.response.data.error
                    }
                })
            },
            showPassword(item) {
                if (item === 'r_pass') {
                    if (this.r_pass === 'password') {
                        this.r_pass = 'text'
                    } else {
                        this.r_pass = 'password'
                    }
                } else {
                    if (this.r_confirm === 'password') {
                        this.r_confirm = 'text'
                    } else {
                        this.r_confirm = 'password'
                    }
                }
            },
            signUp() {
                this.isSubmitted = true;
                this.errors = ''
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }else {
                    this.registerUser(this.userForm)
                        .then((res) => {
                            if(res.status === 200) {
                                this.$refs.next_tab.active = !this.$refs.next_tab.active;
                                // this.$router.push('/dashboardLeads')
                            } else {
                                console.log(res.response.data)
                                this.errors = res.response.data.error
                            }
                        })
                }
            },
            terms() {
                this.$router.push('/tos')
            },
            policy() {
                this.$router.push('/privacy_policy')
            }
        }
    }
</script>

<style scoped lang="scss">
    .form-group > label {
        font-weight: 600;
    }
    .register {
        &_container {
            width: 100%;
            height: 100vh;
            border: 1px solid lightgray;
            background: #fff;
            display: flex;
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }

        &_left-side {
            width: 50%;
            background: $white;
            padding: 65px 65px 0 140px;
            height: 100%;
            @media screen and (max-width: 1440px) {
                padding: 65px 65px 0 140px;
            }
            @media screen and (max-width: 1200px) {
                padding: 30px 40px 0 60px;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
                height: 65px;
                padding: 30px 0 0 0;
            }
            @media screen and (max-width: 425px) {
                padding: 30px 0 0;
            }

            & a {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &_right-side {
            width: 50%;
            height: 99vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (max-width: 768px) {
                width: 100%;
            }

            & .account_text {
                width: 100%;
                padding: 50px 150px;
                display: flex;
                justify-content: flex-end;
                @media screen and (max-width: 1440px) {
                    padding: 25px 90px;
                }
                @media screen and (max-width: 768px) {
                    padding: 10px 60px;
                    justify-content: center;
                }
                @media screen and (max-width: 425px) {
                    padding: 10px 30px;
                    justify-content: center;
                }

                & .dont_have {
                    color: $black;
                    font-weight: 500;
                    font-size: 18px;
                    @media screen and (max-width: 1440px) {
                        font-size: 16px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 14px;
                    }

                    & .sign_text {
                        color: $light_blue;
                        border-bottom: 1px solid $light_blue;
                    }
                }
            }

            &-content {
                max-width: 550px;
                width: 100%;
                height: 100%;
                display: flex;
                padding: 0 20px;
                margin-right: 60px;
                justify-content: center;
                flex-direction: column;
                & .tabs {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    & .card {
                        border: none;
                        width: 100%;
                    }
                    ::v-deep .tab-content {
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        & .tab-pane {
                            width: 100%;
                        }
                    }
                    ::v-deep .nav.nav-pills {
                        width: 100%;
                        display: flex;
                        justify-content: space-between !important;
                        color: #fff;
                        & .nav-item {
                            width: 140px;
                            height: 32px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 4px;
                            /*background: #BBC1C5 !important;*/
                            color: #0a5085;
                            margin: 0 25px;
                            pointer-events: none;
                            & a {
                                color: #0a5085 !important;
                            }
                            & .active {
                                background-color: #0a5085;
                                border-radius: 4px;
                                width: 140px;
                                height: 32px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #fff !important;
                            }
                        }
                    }
                }

                @media screen and (max-width: 1200px) {
                    max-width: 450px;
                }
                @media screen and (max-width: 768px) {
                    margin-right: 0;
                    padding: 0 30px;
                }
                @media screen and (max-width: 425px) {
                    height: auto;
                    margin-top: 15px;
                }


                & .welcome_title {
                    font-size: 35px;
                    font-weight: 600;
                    color: $main_blue;
                    padding: 20px 0;
                    @media screen and (max-width: 1440px) {
                        font-size: 32px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 30px;
                        padding: 0 0 15px;
                    }
                }

                & .input_item {
                    width: 100%;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    @media screen and (max-width: 1200px) {
                        margin-top: 0;
                    }

                    & label {
                        color: $darkBlue;
                        font-weight: 600;
                        font-size: 16px;
                    }

                    & input {
                        background: $blue_grey 0 0 no-repeat padding-box;
                        height: 44px;
                        border: none;
                        padding-left: 20px;
                        @media screen and (max-width: 1200px) {
                            /*height: 35px;*/
                        }

                        &:focus {
                            box-shadow: none;
                            border: 1px solid $light_blue;
                            border-radius: 3px;
                        }
                    }
/*
                    & input {
                        background: $white;
                        width: 100%;
                        height: 44px;
                        border: 1px solid #9E9E9E;
                        border-radius: 10px;
                        padding-left: 20px;

                        &:focus {
                            box-shadow: none;
                            border: 1px solid $light_blue;
                            border-radius: 10px;
                        }
                    }
*/
                    input[type=number] {
                        -moz-appearance: textfield !important;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none !important;
                        margin: 0 !important;
                    }
                    & .date_inputs {
                        width: 100%;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        background: $blue_grey 0 0 no-repeat padding-box;
                        border: none;
                        border-radius: 4px;

                        & input {
                            border: none;
                            height: 42px;
                            width: 75px;

                            &:active,
                            &:focus {
                                border: none;
                                box-shadow: none;
                                outline: none;
                                background: transparent;
                            }
                        }

                        & select {
                            width: 50px;
                            height: 42px;
                            border: none;
                            background: transparent;
                            border-radius: 5px;
                            padding-right: 5px;

                            &:focus {
                                outline: none;
                                box-shadow: none;
                            }
                        }
                    }
                }

                & .dual_inputs {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    @media screen and (max-width: 1200px) {
                        flex-direction: column;
                    }

                    & .dual_input_item {
                        width: 250px;
                        position: relative;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        @media screen and (max-width: 1200px) {
                            width: 100%;
                            margin: 0;
                        }

                        & .eye_close {
                            position: absolute;
                            width: 30px;
                            height: 36px;
                            right: 15px;
                            top: 33px;
                            background-image: url("~@/assets/images/eye_close.svg");
                            background-repeat: no-repeat;
                            background-position: center;
                            cursor: pointer;
                        }

                        & label {
                            color: $darkBlue;
                            font-weight: 600;
                            font-size: 16px;
                        }

                        & input {
                            background: $blue_grey 0 0 no-repeat padding-box;
                            border: none;
                            @media screen and (max-width: 1200px) {
                                height: 35px;
                            }

                            &:focus {
                                box-shadow: none;
                                border: 1px solid $light_blue;
                                border-radius: 3px;
                            }
                        }
                    }
                }
                & .triple_inputs {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    & .triple_input_item {
                        width: 205px;
                        position: relative;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        &.zip {
                            width: 80px;
                        }
                        @media screen and (max-width: 1200px) {
                            width: 100%;
                            margin: 0;
                        }

                        & label {
                            color: $darkBlue;
                            font-weight: 600;
                            font-size: 16px;
                        }

                        & input {
                            background: $blue_grey 0 0 no-repeat padding-box;
                            border: none;
                            @media screen and (max-width: 1200px) {
                                height: 35px;
                            }

                            &:focus {
                                box-shadow: none;
                                border: 1px solid $light_blue;
                                border-radius: 3px;
                            }
                        }
                    }
                }
                & .agree_terms {
                    display: flex;
                    @media screen and (max-width: 1024px) {
                        margin-bottom: 0;
                    }

                    & label {
                        margin-left: 15px !important;
                        color: $darkBlue;
                        font-size: 16px;
                        & span {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                        @media screen and (max-width: 768px) {
                            font-size: 14px;
                        }
                        @media screen and (max-width: 425px) {
                            font-size: 13px;
                        }

                        &.is-invalid {
                            color: #dc3545;
                        }
                    }

                    &.form-check {
                        padding-left: 0;
                        @media screen and (max-width: 768px) {
                            margin-top: 5px;
                        }
                    }
                }
                & .next_btn {
                    width: 120px;
                    height: 50px;
                    background: $light_blue;
                    border-radius: 10px;
                    color: $white;
                    font-weight: 600;
                    border: none;
                    &-container {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
                & .account_btn {
                    width: 100%;
                    height: 50px;
                    background: $light_blue;
                    border-radius: 10px;
                    color: $white;
                    font-weight: 600;
                    border: none;
                    &-container {
                        display: flex;
                        justify-content: flex-end;
                    }
                    &-container {
                        padding: 15px 0;
                    }

                }

                & .register_btn {
                    width: 100%;
                    height: 60px;
                    background: $light_blue;
                    border-radius: 10px;
                    color: $white;
                    font-weight: 600;
                    border: none;
                    @media screen and (max-width: 1200px) {
                        height: 50px;
                    }
                    @media screen and (max-width: 768px) {
                        height: 45px;
                    }
                }

                & .back_website {
                    color: $main_blue;

                    & svg {
                        margin-right: 15px;
                    }
                }
            }
        }

        &_title {
            max-width: 220px;
            width: 100%;
            height: 30px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover !important;
            background: url("~@/assets/images/Logo.svg");
            @media screen and (max-width: 768px) {
                margin: 0 auto;
            }
        }

        &_left-content {
            padding: 75px 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: 768px) {
                display: none;
            }

            & img {
                background-position: center;
                position: relative;
                @media screen and (max-width: 1200px) {
                    width: 500px;
                    height: 600px;
                }
                @media screen and (max-width: 1024px) {
                    width: 420px;
                    height: 520px;
                }
            }
        }
    }
</style>